.backy {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #fbe8de, #fbe8de);
}

.backy2 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #fff, #fff);
}

.backy3 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #e1eaf4, #e1eaf4);
}

.styles_faq-row__2YF3c{
  border-bottom: 1px solid #323033 !important;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  font-size: 40px !important;
}

.p-ExpressCheckoutItem{
  width:400px !important
}





.folded-corner {
  position: relative;
  width: 500px;
  height: 480px;
  background-color: white;
}

.folded-corner::before {
  content: "";
  position: absolute;
  top: 0;
  right: -2px;
  width: 140px; /* Adjust this value to change the size of the fold */
  height:140px; /* Adjust this value to change the size of the fold */
  background-color: #e8e8e8; /* Color of the fold */
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  z-index: 1;
  border-right: none;

}

.custom-input::placeholder {
  color: #30312c; /* This will change the color of the placeholder text */
  opacity: 1; /* This ensures that the placeholder text is fully opaque */
}

.yellow-custom-input::placeholder {
  color: #30312c; /* This will change the color of the placeholder text */
  opacity: 1; /* This ensures that the placeholder text is fully opaque */
  text-align: center;
}



.custom-scroll::-webkit-scrollbar {
  width: 2px; /* Width of the scrollbar */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scroll thumb */
  border-radius: 0px; /* Roundness of the scroll thumb */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scroll thumb on hover */
}



.yellow-custom-input {
  color: #30312c; /* This will change the color of the placeholder text */
  opacity: 1; /* This ensures that the placeholder text is fully opaque */
  text-align: center;
}


.custom-input {
  color: #30312c; /* This will change the color of the placeholder text */
  opacity: 1; /* This ensures that the placeholder text is fully opaque */
}

.buttonSpecial9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: #09382f;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-family: "PlusJSMedium";
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.onboarding::placeholder {
  font-size: 16px !important;
  font-style: oblique 30deg;
}

.match:hover {
  background-color: #f0f0f0;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.Opendates {
  display: inline-block;
}

.Opendates span {
  display: inline-block;
  animation: letter-animation 0.5s ease-in-out forwards;
  opacity: 0;
}

.Opendates span:nth-child(1) {
  animation-delay: 0.1s;
}

.Opendates span:nth-child(2) {
  animation-delay: 0.2s;
}

.Opendates span:nth-child(3) {
  animation-delay: 0.3s;
}

.Opendates span:nth-child(4) {
  animation-delay: 0.4s;
}

.Opendates span:nth-child(5) {
  animation-delay: 0.5s;
}

/* Add more delay for each additional letter */

@keyframes letter-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#overlaySay {
  z-index: 9000;
}


.carousel-container {
  overflow: hidden;
  white-space: nowrap;
  width:100vw
}

.carousel-container::-webkit-scrollbar {
  display: none !important;
  width:100vw
}


.carousel-content {
  display: inline-block;
  
}


.circle:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50px; /* Half of your marginLeft value */
  width: 165px; /* Equal to your marginLeft value */
  height: 1px; /* Height of the dashed line */
  border-top: 1px dashed rgb(63, 59, 69); /* Style of the dashed line */
  transform: translateY(-50%);
}

.hover-border:hover {
  border: 1px solid white !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.hover-border{
  transition: all 0.15s ease-in-out 0s !important
}



